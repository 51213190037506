<template>
    <div id="app" data-content-area="1200px">
        <PageHeader></PageHeader>
        <router-view/>
        <PagePhoneButton/>
        <PageFooter></PageFooter>
    </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';
import PagePhoneButton from './components/PagePhoneButton.vue';

export default {
    data: () => {
        return {

        }
    },
    components: {
        PageHeader,
        PageFooter,
        PagePhoneButton
    },
    watch: {
        // '$route': function (to, from) {
        //     if (window.innerWidth <= 900) {
        //         if (to.name == 'product' && from.name) window.location.reload();
        //     }
        // }
    },
    created() {
        this.$store.dispatch('fetchCategories');
        this.$store.dispatch('fetchProducts');
        this.$store.dispatch('fetchStaticContent');
        this.$store.dispatch('fetchProductsInCart');
        this.$store.dispatch('fetchProductsInFavorites');
        this.$store.dispatch('fetchMetaInfo');

        setTimeout(() => {
            if (!this.$route.query.lang) {
                if (localStorage.getItem('lang')) {
                    this.$store.dispatch('setLang', localStorage.getItem('lang'));
                }
            } else {
                this.$store.dispatch('setLang', this.$route.query.lang);
            }
        }, 100)
    }
}
</script>

<style lang="less">
    @import "./assets/less/index.less";
</style>
